var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('h2',[_vm._v("Страховые компании")])]),_c('b-col',{attrs:{"md":"12"}},[_c('b-table',{attrs:{"items":_vm.items,"fields":_vm.fields,"busy":_vm.loading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-1"},[_vm._v("Идет загрузка ...")])],1)]},proxy:true},{key:"cell(image)",fn:function(data){return [_c('img',{staticStyle:{"object-fit":"contain"},attrs:{"src":data.item.image,"width":"70","height":"70","alt":""}})]}},{key:"cell(action)",fn:function(data){return [_c('div',{staticClass:"d-flex float-right"},[(_vm.isUpdateAvailable)?_c('router-link',{attrs:{"to":{
                            name: 'company-update',
                            params: { id: data.item.id },
                        }}},[_c('b-button',{staticClass:"update__btn",attrs:{"variant":"outline-success","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"Edit2Icon","size":"18"}})],1)],1):_vm._e(),(data.item.active && _vm.isDeleteAvailable)?_c('div',{staticClass:"ml-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal",value:(("modal-" + (data.item.id))),expression:"`modal-${data.item.id}`"}],staticClass:"delete__btn",attrs:{"variant":"outline-danger","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"MinusCircleIcon","size":"18"}})],1),_c('b-modal',{attrs:{"id":("modal-" + (data.item.id)),"cancel-title":"Отменить","cancel-variant":"danger btn-sm","body-class":"deactivate-btn_modal","title":"Деактивация","hide-header-close":"","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger btn-sm"},on:{"click":function($event){return _vm.$bvModal.hide(
                                            ("modal-" + (data.item.id))
                                        )}}},[_vm._v(" Отменить ")]),_c('b-button',{attrs:{"variant":"success btn-sm"},on:{"click":function($event){return _vm.deactivateEntry(
                                            data.item.id,
                                            data.item.active
                                        )}}},[_vm._v(" Деактивировать ")])]},proxy:true}],null,true)},[_vm._v(" Вы действительно хотите деактивировать эту позицию? ")])],1):_vm._e(),(!data.item.active && _vm.isDeleteAvailable)?_c('div',{staticClass:"ml-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}},{name:"b-modal",rawName:"v-b-modal",value:(("modal-" + (data.item.id))),expression:"`modal-${data.item.id}`"}],staticClass:"delete__btn",attrs:{"variant":"outline-info","size":"sm"}},[_c('feather-icon',{attrs:{"icon":"CornerUpLeftIcon","size":"18"}})],1),_c('b-modal',{attrs:{"id":("modal-" + (data.item.id)),"cancel-title":"Отменить","cancel-variant":"danger btn-sm","body-class":"deactivate-btn_modal","title":"Активация","hide-header-close":"","centered":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"danger btn-sm"},on:{"click":function($event){return _vm.$bvModal.hide(
                                            ("modal-" + (data.item.id))
                                        )}}},[_vm._v(" Отменить ")]),_c('b-button',{attrs:{"variant":"success btn-sm"},on:{"click":function($event){return _vm.deactivateEntry(
                                            data.item.id,
                                            data.item.active
                                        )}}},[_vm._v(" Активировать ")])]},proxy:true}],null,true)},[_vm._v(" Вы действительно хотите активировать эту позицию? ")])],1):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }